// $secondary: red;

.phoneNumberField input {
  border: none;
  &:focus-visible {
    outline: none !important;
  }

  .PhoneInputCountrySelect svg {
    display: none;
  }
}
.input-group .form-control:focus {
  box-shadow: none !important;
}

.form-control .is-invalid {
  border-radius: 0px 0.5rem 0.5rem 0px;
}

.signUp_Form {
  .invalid-feedback {
    display: block !important;
  }
}

.signUp_Form.model {
  height: 54vh;
  overflow-y: scroll;
}

.fc.fc-media-screen.fc-theme-bootstrap {
  height: 1300px;
}

#select_doctor div[id^="react-select"] {
  z-index: 999 !important;
}

.table-responsive.py-4 {
  width: 100% !important;
}

.table-responsive.py-4 .react-bootstrap-table {
  overflow-x: auto !important;
  width: 100% !important;
}

.doted-text {
  display: inline-block;
  margin-bottom: 15px;
}

.fc-daygrid-event.fc-daygrid-dot-event span,
.fc-daygrid-event.fc-daygrid-block-event .fc-event-main {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.fontSizeSm {
  font-size: 12px !important;
}

.fc .fc-daygrid-day-frame {
  overflow: auto !important;
}

/* width */
.fc .fc-daygrid-day-frame::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.fc .fc-daygrid-day-frame::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.fc .fc-daygrid-day-frame::-webkit-scrollbar-thumb {
  background: #999;
  border-radius: 10px;
}

/* Handle on hover */
.fc .fc-daygrid-day-frame::-webkit-scrollbar-thumb:hover {
  background: #555;
}
